import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import MakeSiteBase from './layout/MakeSiteBase';

import "assets/scss/material-kit-react.scss?v=1.8.0";

class App extends Component {

  render() {

    return (
        <Switch>
          <Route exact path="/" component={MakeSiteBase} />
        </Switch>
    );
  }
}

export default App;