/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import CardHeader from "components/Card/CardHeader.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function MethodSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>¿Cuál es la metodología del Kit Make?</h2>
      <ScrollableAnchor id={'method'}>
      <h5 className={classes.description}>
        La esencia de los ecosistemas de conocimiento e innovación se centra en la identificación de activos intangibles que no sólo residen en las competencias de las personas sino en aquéllos otros activos que potencian o dificultan su desarrollo, cuya naturaleza puede ser estructural o relacional.
        De esta forma, la metodología del KIT MAKE se asienta en los siguientes 4 elementos:
      </h5>
      </ScrollableAnchor>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
          <Card style={{height: "15rem"}}>
            <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>1</span></CardHeader>
              <CardBody>
                <p>
                  Análisis de activos intangibles territoriales, según metodología de capital intelectual (basado en modelo intellectus, UAM).
                </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
          <Card style={{height: "15rem"}}>
            <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>2</span></CardHeader>
              <CardBody>
              <p>
                Identificación de dominios de conocimiento clave del territorio, según metodología de mapas de conocimiento (Kmap, desarrollado por ICA2).
              </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
          <Card style={{height: "15rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>3</span></CardHeader>
              <CardBody>
                <p>
              Diagnóstico del nivel de madurez de la gestión del conocimiento en el ecosistema de innovación, siguiendo metodología Knowledge Management Strategy Planner (desarrollada por ICA2).
              </p>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
          <Card style={{height: "15rem"}}>
          <CardHeader color="info"><span style={{ fontSize: 19, fontWeight: 'bold' }}>4</span></CardHeader>
              <CardBody>
                <p>
              Análisis del colectivo de agentes dinamizadores del ecosistema de innovación, siguiendo metodología Knowledge Management Strategy Planner (desarrollada por ICA2).
              </p>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <h5 className={classes.description}>
        El Kit permite realizar estos análisis y, en virtud de los resultados, aporta diferentes recomendaciones particulares y generales. El mero hecho de visualizar los componentes del Kit ya supone un enriquecimiento de la perspectiva de análisis de los agentes implicados con la estrategia u operativa de los ecosistemas de conocimiento e innovación. 
      </h5>
    </div>
  );
}
/* eslint-disable no-unused-vars */