/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";

// core components
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import NavPills from "components/NavPills/NavPills.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Paper from '@material-ui/core/Paper';
import InfoArea from "components/InfoArea/InfoArea.js";
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

const useStyles = makeStyles(styles);

export default function WhatForSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>¿Para qué sirve el Kit Make?</h2>
      <ScrollableAnchor id={'whatfor'}>
      <h5 className={classes.description}>
        Fundamentalmente pretende aportar una visión distinta para el diagnóstico y, por ende, para la toma de decisiones tanto de los denominados “policy makers”, como de los agentes involucrados en el sistema de estructuras dinamizadoras de los ecosistemas de innovación. Esta visión se caracteriza por la consideración de los activos intangibles y la gestión del conocimiento en su base de análisis.
      </h5>
      </ScrollableAnchor>
      
      <NavPills
        alignCenter={true}
        color="icablue"
        tabs={[
          {
            tabButton: "Componentes",
            tabContent: (
              <span className={classes.textLeft}>
                <p style={{ textAlign: 'center', fontSize: '14px' }}>
                  De forma más concreta, los resultados esperados, y, por tanto, las necesidades que puede cubrir este Kit, se articulan alrededor de los componentes del mismo:
                </p>
                <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>

                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Identificar los activos intangibles territoriales y dar cuenta de su situación, proponiendo recomendaciones para su evolución.</p>
                </Paper>  
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Identificar y caracterizar los dominios de conocimiento sobre los que se asientan las actividades económicas y líneas de investigación diferenciadoras de la región, estableciendo recomendaciones clave.</p>
                </Paper>   
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Identificar la situación específica en la región de los diferentes ejes de actuación que componen la gestión del conocimiento, creando también recomendaciones para cada uno de dichos ejes.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Identificar a los agentes dinamizadores del ecosistema de innovación además de perfilar su posicionamiento frente a los ejes de gestión del conocimiento mencionados, aportando una visión particular de cada agente y general de todos los agentes del territorio.</p>
                </Paper>
                </GridItem>
                </GridContainer>
              </span>
            )
          },
          {
            tabButton: "Servicios",
            tabContent: (
              <span className={classes.textLeft}>
                <p style={{ textAlign: 'center', fontSize: '14px' }}>
                  De forma más orientada a los servicios que se derivan de este Kit aparecen los siguientes:
                </p>
                <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <Paper style={{height: "14rem"}} elevation={0}>
                    <p style={{ textAlign: 'center', fontSize: '17px' }}>Diagnóstico de ecosistemas territoriales de conocimiento e innovación.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Asesoramiento de policy makers y agentes dinamizadores de ecosistemas de conocimiento e innovación.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Formulación de estrategias para potenciar ecosistemas de conocimiento e innovación.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>Configuración y diseño de ecosistemas de conocimiento e innovación.</p>
                </Paper>
                </GridItem>
                </GridContainer>
              </span>
            )
          },
          {
            tabButton: "Impacto",
            tabContent: (
              <span className={classes.textLeft}>
                <p style={{ textAlign: 'center', fontSize: '14px' }}>
                  Finalmente, el Kit pretende impactar en:
                </p>
                <br />
                <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                  <Paper style={{height: "14rem"}} elevation={0}>
                    <p style={{ textAlign: 'center', fontSize: '17px' }}>El incremento de la atracción y retención de agentes dentro del ecosistema.</p>
                  </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>La mejora del posicionamiento diferenciador del territorio.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>El estímulo de la conexión del territorio con el mundo.</p>
                </Paper>
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                <Paper style={{height: "14rem"}} elevation={0}>
                  <p style={{ textAlign: 'center', fontSize: '17px' }}>El aumento de las transacciones de conocimiento en el ecosistema.</p>
                </Paper>
                </GridItem>
                </GridContainer>
              </span>
            )
          }
        ]}
      />
      </div>
    
  );
}
/* eslint-disable no-unused-vars */