/* eslint-disable no-unused-vars */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import ScrollableAnchor from 'react-scrollable-anchor';
import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img/faces/avatar.jpg";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function ResultsSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Resultados esperados del Kit Make</h2>
      <ScrollableAnchor id={'results'}>
      <h5 className={classes.description}>
        Con la aplicación del Kit MAKE se espera potenciar el trabajo de diagnóstico, asesoramiento y articulación de los ecosistemas de conocimiento e innovación facilitando resultados como:
      </h5>
      </ScrollableAnchor>
      <div>
        <p className={classes.textLeft}>
        •	Conocer mejor el contexto territorial desde una perspectiva de intangibles, interpretando dicha perspectiva como la verdadera capa de aportación de valor.<br />
        •	Potenciar los elementos realmente diferenciadores del territorio, ajustando la estrategia asociada a los ecosistemas de conocimiento e innovación a sus particularidades.<br />
        •	Apoyar y justificar más objetivamente la toma de decisiones estratégicas sobre los ecosistemas de conocimiento e innovación.<br />
        •	Potenciar la integración y sinergias en la configuración y funcionamiento de los agentes del ecosistema, evitando solapes.<br />
        •	Ajustar los planteamientos, recursos, etc., existentes a través de un marco de referencia basado en los patrones de la economía del conocimiento.
        </p>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */