/* eslint-disable no-unused-vars */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MapIcon from '@material-ui/icons/Map';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SpaRoundedIcon from '@material-ui/icons/SpaRounded';
import HomeWorkIcon from '@material-ui/icons/Business';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import ScrollableAnchor from 'react-scrollable-anchor';

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Kit Make</h2>
          <ScrollableAnchor id={'what'}>
          <h5 className={classes.description}>
            MAKE es el acrónimo asociado a un kit metodológico que pretende convertirse en una referencia útil para apoyar la función de los agentes dinamizadores en el diagnóstico, asesoramiento y articulación de ecosistemas territoriales de conocimiento e innovación.
          </h5>
          </ScrollableAnchor>             
        </GridItem>
      </GridContainer>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={10}>
          <h5 className={classes.description}>
           Son muchos los responsables de políticas y estrategias territoriales que buscan desarrollar soluciones favorables a la innovación desde una perspectiva sistémica, es decir, generando un planteamiento integrador de los factores diferenciadores del territorio en cuestión considerando aquellos activos que se encuentran en la base de la innovación y que se caracterizan por su naturaleza intangible y los procesos de gestión del conocimiento que los movilizan.
          </h5>
          <h5 className={classes.description}>
            Así, el proyecto MAKE aporta una herramienta para la economía del conocimiento y el posicionamiento de los territorios en este paradigma, manejando el ámbito del capital intelectual, la gestión del conocimiento y el papel de los actores responsables de la dinamización de estos ecosistemas de innovación.
          </h5>                    
        </GridItem>
      </GridContainer>
      <div style={{ marginBottom: "35px" }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Capital Intelectual"
              description=""
              icon={SupervisedUserCircleIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Gestión de Conocimiento"
              description=""
              icon={MapIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Agentes Dinamizadores"
              description=""
              icon={HomeWorkIcon}
              iconColor="danger"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
/* eslint-disable no-unused-vars */