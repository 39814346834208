import React, { Component } from 'react';

export const MakeSiteContext = React.createContext();
export const MakeSiteConsumer = MakeSiteContext.Consumer

class MakeSiteProvider extends Component {

  state = {
    
  }
  
  render () {
    return (
      <MakeSiteContext.Provider value={this.state}>
        {this.props.children}
      </MakeSiteContext.Provider>
    )
  }
}
export default MakeSiteProvider;